<template>
  <NavBar />
  <!-- <div class="row absolute-top vertical-bottom backgroundColor">
    <div class="col">
      <q-img src="../assets/cuttrianglepoly.png"></q-img>
    </div>
  </div> -->
  <div class="absolute-top vertical-top bg-image backgroundColor">
    <div class="row" style="height: 143vh">
      <div class="col-5">
        <p
          class="text-h1 gentium animate__animated animate__fadeInRight"
          style="margin-left: 2.5vw; margin-top: 18vh"
        >
          Welcome to <br />
          the <i>future</i> of <br />
          <b>learning</b>.
        </p>
        <p
          class="
            text-h3
            gentium
            animate__animated animate__fadeInRight animate__delay-2s
          "
          style="margin-left: 2.5vw; margin-top: 5vh"
        >
          <b>Skeptical?</b>
        </p>
        <p
          class="
            text-h4
            gentium
            animate__animated animate__fadeInUp animate__delay-2s
          "
          style="margin-left: 2.5vw; margin-top: 0vh"
        >
          Check out the process we use to ensure that knowledge turns into
          academic <i>and</i> social skills, here at
          <text class="zerudite-logo">zerudite</text>.
        </p>
        <span
          class="
            material-icons
            text-h1
            animate__animated animate__fadeInUp animate__delay-2s
          "
          id="process"
          style="margin-left: 1.5vw"
          >arrow_downward</span
        >
      </div>
      <div class="col-1"></div>
      <div class="col-6">
        <q-img
          style="margin-top: 16vh; margin-left: 8vw"
          src="../assets/storyset/Learning-amico.svg"
          width="33vw"
          height="33vw"
        ></q-img>
      </div>
    </div>
    <div class="row q-pb-xl">
      <div class="col-6">
        <q-img
          style="margin-left: 11vw"
          src="../assets/storyset/Coins.gif"
          width="30vw"
          height="30vw"
        ></q-img>
      </div>
      <div class="col-4" style="margin-left: 4vw">
        <p class="text-h2 gentium">
          Mentor someone, <i>earn</i> <b>credits</b>!
        </p>
        <p class="text-h5 gentium">
          <text class="zerudite-logo">zerudite</text> focuses on collaborative
          learning. Find someone who needs help on a topic that you are an
          expert on? Earn credits by helping them with their worries through an
          online learning session, all integrated in our app for your
          convenience!
        </p>
      </div>
    </div>

    <div class="row q-pb-xl">
      <div class="col-1"></div>
      <div class="col-4" style="margin-left: 3vw">
        <p class="text-h2 gentium right-side-text">
          <i>Spend</i> <b>credits</b> to get mentored!
        </p>
        <p class="text-h5 gentium right-side-text">
          Need help on a particular topic? Offer some credits to get assistance
          and provide your availability! Other users will be able to see your
          offer and provide assistance through a learning session!
        </p>
      </div>
      <div class="col-6">
        <q-img
          style="margin-left: 9vw"
          src="../assets/storyset/Learning.gif"
          width="30vw"
          height="30vw"
        ></q-img>
      </div>
    </div>

    <div class="row q-pb-xl">
      <div class="col-6">
        <q-img
          style="margin-left: 11vw"
          src="../assets/storyset/Wallet.gif"
          width="30vw"
          height="30vw"
        ></q-img>
      </div>
      <div class="col-4" style="margin-left: 4vw">
        <p class="text-h2 gentium">
          <i>Out of</i> <b>credits</b>? Or have <i>too many</i>?
        </p>
        <p class="text-h5 gentium">
          Credits can be purchased, but only if you have very few. Excess
          credits above a certain threshold can be redeemed for real world rewards as
          well!
        </p>

        <p class="text-h5 gentium">
          You will receive 1000 bonus credits upon verifying yourself as a
          student from us for free! Early access registrants will also receive
          bonus credits.
        </p>
      </div>
    </div>

    <div class="row q-pb-xl">
      <div class="col-1"></div>
      <div class="col-4" style="margin-left: 3vw">
        <p class="text-h2 gentium right-side-text">
          Made for <b>students</b>, by <b>students</b>.
        </p>
        <p class="text-h5 gentium right-side-text">
          Our development team consists of students who understand the process
          of learning in schools and the challenges many students face outside
          of the classroom.
        </p>
      </div>
      <div class="col-6">
        <q-img
          style="margin-left: 9vw"
          src="../assets/storyset/Nerd.gif"
          width="30vw"
          height="30vw"
        ></q-img>
      </div>
    </div>

    <div class="row q-pb-xl">
      <div class="col-6">
        <q-img
          style="margin-left: 11vw"
          src="../assets/storyset/Choice.gif"
          width="30vw"
          height="30vw"
        ></q-img>
      </div>
      <div class="col-4" style="margin-left: 4vw">
        <p class="text-h2 gentium">
          <i>Still</i> have questions? Check out our <b>FAQs</b>!
        </p>
        <p class="text-h5 gentium">
          Our <b>FAQs</b> provide a very in-depth explanation of how
          <i>you</i> can thrive at <text class="zerudite-logo">zerudite</text>.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <q-img src="../assets/bottomtrianglepoly.png"></q-img>
      </div>
    </div>

    <div class="row footer">
      <div
        class="col text-center"
        style="justify-content: space-evenly; flex: 1;"
      >
        <q-btn
          @click="$router.replace('/signup')"
          no-caps
          push
          label="Get Started"
          style="
            background-color: #f8e9a1;
            border-radius: 20px;
            width: 10vw;
            white-space: nowrap;
            color: black;
            font-family: Montserrat;
            font-weight: 600;
          "
        />
        <div />
        <br>
        <q-btn
          @click="$router.replace('/login')"
          no-caps
          push
          label="Log In"
          style="
            background-color: #a8d0e6;
            border-radius: 20px;
            width: 8vw;
            white-space: nowrap;
            color: black;
            font-family: Montserrat;
            font-weight: 600;
          "
        />
        <p></p>
        <p class="text-h6 gentium text-center" style="color: white">
          Illustrations by <a href="https://storyset.com/" style="color: white">Storyset</a>
        </p>
      </div>
      <div
        class="col text-center"
        style="justify-content: space-evenly; flex: 1"
      >
        <p class="text-h3 gentium text-center" style="color: white">
          Need Help?
        </p>
        <q-btn
          @click="$router.replace('/homepage#process')"
          no-caps
          label="Our Process"
          style="
            opacity: 80%;
            background-color: #1de9b6;
            border-radius: 20px;
            width: 20vw;
            white-space: nowrap;
            color: white;
            font-family: Montserrat;
            font-weight: 600;
          "
        />
        <q-btn
          @click="$router.replace('/faq')"
          no-caps
          label="Frequently Asked Questions"
          style="
            opacity: 80%;
            margin-top: 2vh;
            background-color: #1de9b6;
            border-radius: 20px;
            width: 20vw;
            white-space: nowrap;
            color: white;
            font-family: Montserrat;
            font-weight: 600;
          "
        />
      </div>
      <div class="col" style="justify-content: space-evenly; flex: 1">

        <p class="text-h3 gentium text-center" style="color: white">
          Developed by:
        </p>
        <p class="text-h6 gentium text-center" style="color: white">
          <a style="color: white" href="https://www.linkedin.com/in/spandan-goel">Spandan Goel</a>
        </p>
        <p class="text-h6 gentium text-center" style="color: white">
          <a style="color: white" href="https://www.linkedin.com/in/frankgao04">Frank Gao</a>
        </p>

      </div>
    </div>
  </div>
</template>


<script>
import NavBar from "../components/NavBar.vue";

export default {
  name: "Homepage",
  components: {
    NavBar,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Gentium+Basic:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@font-face {
  font-family: "Farray";
  src: url("../assets/fonts/FARRAY.otf");
}

.bg-image {
  background-image: url("../assets/cuttrianglepoly.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.backgroundColor {
  background-color: #f7f3dc;
}

.footer {
  position: absolute;
  bottom: 12vh;
  left: 10vw;
  right: 10vw;
  justify-content: center;
}

.gentium {
  font-family: Gentium Basic;
}

.right-side-text {
  text-align: right;
}

.zerudite-logo {
  font-family: Montserrat;
  font-weight: 700;
}
</style>